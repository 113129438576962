import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import "./horizontalscroll.css";

const HorizontalScroll = ({ horizontalSections }) => {
  const containerRef = useRef(null);
  const sectionRefs = useRef([]); // Store refs for each section
  const [isAtTop, setIsAtTop] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current section index
  const [currentTranslateX, setCurrentTranslateX] = useState(0);
  const [scrolling, setScrolling] = useState(false); // Track if a scroll is in progress

  const containerWidth = horizontalSections.length * window.innerWidth;
  const containerHeight = window.innerHeight * horizontalSections.length;

  const scrollStep = window.innerHeight;

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerTopOffset = containerRef.current.getBoundingClientRect().top;
        setIsAtTop(containerTopOffset <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollEvent = () => {
      if (!scrolling && isAtTop) {
        const currentScrollY = window.scrollY;
        const maxIndex = horizontalSections.length - 1;
        let newSectionIndex = Math.round(currentScrollY / scrollStep);

        if (newSectionIndex > maxIndex) {
          newSectionIndex = maxIndex;
        }

        if (newSectionIndex !== currentIndex) {
          setScrolling(true);
          setCurrentIndex(newSectionIndex);
        }
      }
    };

    const handleScrollEnd = () => {
      if (scrolling) {
        const targetX = -(currentIndex * window.innerWidth);
        setCurrentTranslateX(targetX);
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScrollEvent);
    window.addEventListener('scroll', handleScrollEnd);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
      window.removeEventListener('scroll', handleScrollEnd);
    };
  }, [scrolling, isAtTop, currentIndex, scrollStep, horizontalSections.length]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        height: `${containerHeight}px`,
      }}
    >
      <motion.div
        style={{
          position: 'sticky',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
        }}
      >
        <motion.div
          animate={{ x: currentTranslateX }}
          transition={{ type: "spring", stiffness: 100, damping: 20 }}
          style={{
            display: 'flex',
            width: `${containerWidth}px`,
            height: '100%',
            /* backgroundImage: `url(${require('../../images/widescreen.webp')})`, */
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
        >
          {horizontalSections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (sectionRefs.current[index] = el)} // Store the ref for each section
              className="horizontal-scroll-section-container"
              style={{
                height: '100vh',
                background: `${section.backgroundColor}`,
              }}
            >
              <div
                className="horizontal-scroll-section-content"
                style={{ backgroundColor: section.backgroundColor }}
              >
                {section.headline && <h2>{section.headline}</h2>}
                {section.image && <img src={section.image} alt="horizontal-scroll-section-image" />}
              </div>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HorizontalScroll;
