import React, { useState, useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import "./footer.css";
import { CompanyName } from "../../data";
import { NavLink } from "react-router-dom";
import newYear from "../../data/CurrentYear";
import useLoginStatus from "../../hooks/loginCheck";

const logoDark = require("../../images/jk_logo_black.png");
const logoLight = require("../../images/jk_logo_white.png");

const CompanyNameTitle = CompanyName;

const Footer = () => {
  const isLoggedIn = useLoginStatus();
  const [logo, setLogo] = useState(logoDark);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  function handleLogo() {
    if (localStorage.getItem("theme") === "dark") {
      setLogo(logoLight);
    } else setLogo(logoDark);
  }

  useEffect(() => {
    window.addEventListener("storage", handleLogo);
    handleLogo();

    return () => {
      window.addEventListener("storage", handleLogo);
    };
  });

  return (
    <footer id="footer" ref={ref}>
      {isLoggedIn && (
        <div
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          className={["footer"].join(" ")}
        >
          <div className="footer-first-container">
            <div className="footer-logo_container">
              <NavLink to="/">
                <img className="footer-logo" src={logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="footer-box">
              <h5 className="footer-overview">Seitenübersicht</h5>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">Über mich</NavLink>
              <NavLink to="/services">Services</NavLink>
              <NavLink to="/projects">Galerie</NavLink>
              <NavLink to="/contact">Kontakt</NavLink>
              <NavLink to="/legal">Rechtliches</NavLink>
            </div>
            <div className="footer-box">
              <h5>Über mich</h5>
              <p className="footer-text">
              Joshua Krieg – Dein selbstständiger Webentwickler für einzigartige Websites! Mit modernsten Technologien und maßgeschneiderten Lösungen setze ich deine Visionen um. Besuche meine Website und entdecke, wie ich dein Projekt auf das nächste Level heben kann.
              </p>
            </div>
            <div className="footer-box">
              <h5>Kontakt</h5>
              <p className="footer-text">
                Hast du Fragen oder benötigst weitere Informationen? Kontaktiere
                mich gerne per E-Mail unter{" "}
                <a className="text-bright" href="mailto:info@joshuakrieg.com">
                  info@joshuakrieg.com
                </a>{" "}
                oder telefonisch unter{" "}
                <a className="text-bright" href="tel:01603465558">
                  0160 3465559
                </a>
                . Ich stehe jederzeit zur Verfügung.
              </p>
            </div>
          </div>
          <div className="footer-second-container">
            <div className="footer-box full">
              <p className="footer-text">
                Copyright &copy; {CompanyNameTitle} {newYear} - Umsetzung:
                Joshua Krieg
              </p>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
