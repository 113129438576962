import React, { Suspense, lazy } from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ScrollToTop from './hooks/ScrollToTop';
import { Footer, NavigationBar, ToTopButton, Fullscreen, Cursor, Screensaver } from './components';
import 'animate.css';

// Lazy load the page components from the index.js inside the pages directory
const Home = lazy(() => import('./pages').then(module => ({ default: module.Home })));
const About = lazy(() => import('./pages').then(module => ({ default: module.About })));
const Contact = lazy(() => import('./pages').then(module => ({ default: module.Contact })));
const Legal = lazy(() => import('./pages').then(module => ({ default: module.Legal })));
const Customization = lazy(() => import('./pages').then(module => ({ default: module.Customization })));
const Projects = lazy(() => import('./pages').then(module => ({ default: module.Projects })));
const Services = lazy(() => import('./pages').then(module => ({ default: module.Services })));
const Test = lazy(() => import('./pages').then(module => ({ default: module.Test })));
const NotFound = lazy(() => import('./pages').then(module => ({ default: module.NotFound })));

const container = document.getElementById('root');
const root = createRoot(container);

let primaryColor = localStorage.getItem('primarycolor');
let secondaryColor = localStorage.getItem('secondarycolor');
let tertiaryColor = localStorage.getItem('tertiarycolor');
let backgroundColor = localStorage.getItem('backgroundcolor');
let activeColor = localStorage.getItem('activecolor');

if (!primaryColor) {
  document.documentElement.style.setProperty('--primary-color', "#1e1e1e");
  localStorage.setItem('primarycolor', "#1e1e1e")
} else {
  document.documentElement.style.setProperty('--primary-color', primaryColor);
}
if (!secondaryColor) {
  document.documentElement.style.setProperty('--secondary-color', "#eaeaea");
  localStorage.setItem('secondarycolor', "#eaeaea")
} else {
  document.documentElement.style.setProperty('--secondary-color', secondaryColor);
}
if (!tertiaryColor) {
  document.documentElement.style.setProperty('--tertiary-color', "#0d0d0d");
  localStorage.setItem('tertiarycolor', "#0d0d0d")
} else {
  document.documentElement.style.setProperty('--tertiary-color', tertiaryColor);
}
if (!backgroundColor) {
  document.documentElement.style.setProperty('--background-color', "#f0f0f0");
  localStorage.setItem('backgroundcolor', "#f0f0f0")
} else {
  document.documentElement.style.setProperty('--background-color', backgroundColor);
}
if (!activeColor) {
  document.documentElement.style.setProperty('--active-color', "#00a6fb");
  localStorage.setItem('activecolor', "#00a6fb")
} else {
  document.documentElement.style.setProperty('--active-color', activeColor);
}

root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Cursor />
      <Fullscreen isLoggedIn={false} />
      <header id='header'>
        <NavigationBar />
      </header>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/customization" element={<Customization />} />
          <Route path="/test" element={<Test />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Suspense>
      <ToTopButton />
      <Screensaver delay={60000} />
      <Footer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
serviceWorkerRegistration.register();
