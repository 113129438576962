import "./userform.css";
import { useState, useRef } from "react";
import { Darkmode, Saved } from "../../components";
import emailjs from "@emailjs/browser";
import { useInView, motion } from "framer-motion";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import Icon from "../../helpers/Icon";

function Userform({
  userform,
  customizable,
  editable,
  darkmodeActive,
  sendForm,
  disableAnimation,
}) {
  const [firstname, setName] = useState(
    localStorage.getItem("firstname") || ""
  );

  const [lastname, setLastname] = useState(
    localStorage.getItem("lastname") || ""
  );

  const [mail, setMail] = useState(localStorage.getItem("mail") || "");

  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");

  const [message, setMessage] = useState("");

  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primarycolor") || ""
  );

  const [secondaryColor, setSecondaryColor] = useState(
    localStorage.getItem("secondarycolor") || ""
  );

  const [tertiaryColor, setTertiaryColor] = useState(
    localStorage.getItem("tertiarycolor") || ""
  );

  const [backgroundColor, setBackgroundColor] = useState(
    localStorage.getItem("backgroundcolor") || ""
  );

  const [activeColor, setActiveColor] = useState(
    localStorage.getItem("activecolor") || ""
  );

  const [userinitials, setUserinitials] = useState("");

  const [saveDataDialogue, setSaveDataDialogue] = useState(
    "Persönliche Daten speichern"
  );

  const [saveThemeDialogue, setSaveThemeDialogue] = useState(
    "Anpassungen speichern"
  );
  const [resetThemeDialogue, setResetThemeDialogue] = useState("ZURÜCKSETZEN");

  const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const formData = useRef();
  const [submitted, setSubmitted] = useState(false);

  const r = document.querySelector(":root");

  let saved = Saved;

  const isOnline = useOnlineStatus();

  const userformContainerRef = useRef(null);

  const isInView = useInView(userformContainerRef, { once: true });

  const tapAnimation = { scale: 0.95 };

  function removeDataTheme() {
    document.documentElement.removeAttribute("data-theme");
  }

  function onChangeName(e) {
    setName(e.target.value);
  }
  function onChangeLastname(e) {
    setLastname(e.target.value);
  }
  function onChangeMail(e) {
    setMail(e.target.value);
  }
  function onChangePhone(e) {
    setPhone(e.target.value);
  }
  function onChangeMessage(e) {
    setMessage(e.target.value);
  }
  function onChangePrimaryColor(e) {
    setPrimaryColor(e.target.value);
    r.style.setProperty("--primary-color", e.target.value);
    localStorage.setItem("theme", "light");
    removeDataTheme();
  }
  function onChangeSecondaryColor(e) {
    setSecondaryColor(e.target.value);
    r.style.setProperty("--secondary-color", e.target.value);
    localStorage.setItem("theme", "light");
    removeDataTheme();
  }
  function onChangeTertiaryColor(e) {
    setTertiaryColor(e.target.value);
    r.style.setProperty("--tertiary-color", e.target.value);
    localStorage.setItem("theme", "light");
    removeDataTheme();
  }
  function onChangeBackgroundColor(e) {
    setBackgroundColor(e.target.value);
    r.style.setProperty("--background-color", e.target.value);
    localStorage.setItem("theme", "light");
    removeDataTheme();
  }
  function onChangeActiveColor(e) {
    setActiveColor(e.target.value);
    r.style.setProperty("--active-color", e.target.value);
    localStorage.setItem("theme", "light");
    removeDataTheme();
  }
  function onChangeSave() {
    setUserinitials(userinitials);
    setSaveThemeDialogue(saved);
    setSaveDataDialogue(saved);
    window.dispatchEvent(new Event("custom-colors"));
  }
  function onChangeReset() {
    setPrimaryColor("#1e1e1e");
    setSecondaryColor("#eaeaea");
    setBackgroundColor("#f0f0f0");
    setActiveColor("#00a6fb");
    setTertiaryColor("#000");
    setResetThemeDialogue("Zurückgesetzt!");
    if (!prefersDark) {
      window.dispatchEvent(new Event("custom-colors"));
    }
  }

  function onSubmitForm(e) {
    e.preventDefault();
    if (firstname !== null) {
      localStorage.setItem("firstname", firstname);
    } else {
      localStorage.setItem("firstname", "");
    }
    if (lastname !== null) {
      localStorage.setItem("lastname", lastname);
    } else {
      localStorage.setItem("lastname", "");
    }
    if (mail !== null) {
      localStorage.setItem("mail", mail);
    } else {
      localStorage.setItem("mail", "");
    }
    if (phone !== null) {
      localStorage.setItem("phone", phone);
    } else {
      localStorage.setItem("phone", "");
    }
    if (message !== null) {
      localStorage.setItem("message", message);
    } else {
      localStorage.setItem("message", "");
    }

    window.dispatchEvent(new Event("storage"));

    emailjs
      .sendForm(
        "service_jbbfs96",
        "template_pfc60xn",
        formData.current,
        "L4sX3oigbmsyvO6rc"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  function onSubmitTheme(e) {
    e.preventDefault();

    localStorage.setItem("primarycolor", primaryColor);
    localStorage.setItem("secondarycolor", secondaryColor);
    localStorage.setItem("tertiarycolor", tertiaryColor);
    localStorage.setItem("backgroundcolor", backgroundColor);
    localStorage.setItem("activecolor", activeColor);

    window.dispatchEvent(new Event("storage"));

    if (primaryColor !== null) {
      localStorage.setItem("primarycolor", primaryColor);
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor
      );
      localStorage.setItem("customized", true);
    } else {
      localStorage.setItem("primarycolor", "#1e1e1e");
      document.documentElement.style.setProperty("--primary-color", "#1e1e1e");
    }
    if (secondaryColor !== null) {
      localStorage.setItem("secondarycolor", secondaryColor);
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondaryColor
      );
      localStorage.setItem("customized", true);
    } else {
      localStorage.setItem("secondarycolor", "#eaeaea");
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#eaeaea"
      );
    }
    if (tertiaryColor !== null) {
      localStorage.setItem("tertiarycolor", tertiaryColor);
      document.documentElement.style.setProperty(
        "--tertiary-color",
        tertiaryColor
      );
      localStorage.setItem("customized", true);
    } else {
      localStorage.setItem("tertiarycolor", "#0d0d0d");
      document.documentElement.style.setProperty("--tertiary-color", "#0d0d0d");
    }
    if (backgroundColor !== null) {
      localStorage.setItem("backgroundcolor", backgroundColor);
      document.documentElement.style.setProperty(
        "--background-color",
        backgroundColor
      );

      localStorage.setItem("customized", true);
    } else {
      localStorage.setItem("backgroundcolor", "#f0f0f0");
      document.documentElement.style.setProperty(
        "--background-color",
        "#f0f0f0"
      );
    }
    if (activeColor !== null) {
      localStorage.setItem("activecolor", activeColor);
      document.documentElement.style.setProperty("--active-color", activeColor);

      localStorage.setItem("customized", true);
    } else {
      localStorage.setItem("activecolor", "#00a6fb");
      document.documentElement.style.setProperty("--active-color", "#00a6fb");
    }
  }

  return (
    <>
      {submitted && (
        <div
          className="userform-container success"
          ref={userformContainerRef}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <h3>Nachricht gesendet!</h3>
          <p className="text-bright">
            Deine Nachricht wurde erfolgreich versendet. Wir werden uns zeitnah
            bei Dir melden.
          </p>
          <Icon iconFa="fas" iconName="fa-check-circle" />
        </div>
      )}
      {!submitted && (
        <div
          key={userform.id}
          className={["userform-container"].join(" ")}
          ref={userformContainerRef}
          style={{
            transform: !disableAnimation
              ? isInView
                ? "none"
                : "translateY(100px)"
              : null,
            opacity: !disableAnimation ? (isInView ? 1 : 0) : null,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <form
            ref={formData}
            onSubmit={sendForm ? onSubmitForm : onSubmitTheme}
          >
            {editable && !isOnline && (
              <p>
                Solange du nicht mit dem Internet verbunden bist, kannst du
                keine Nachricht senden.
              </p>
            )}
            {editable &&
              isOnline &&
              userform.map(
                (userform) =>
                  userform.firstname && (
                    <div key={userform.id}>
                      <motion.input
                        whileTap={tapAnimation}
                        required
                        type="text"
                        placeholder="Vornamen eingeben"
                        value={firstname}
                        onChange={onChangeName}
                        name="firstname"
                      />
                    </div>
                  )
              )}
            {editable &&
              isOnline &&
              userform.map(
                (userform) =>
                  userform.lastname && (
                    <div key={userform.id}>
                      <motion.input
                        whileTap={tapAnimation}
                        required
                        type="text"
                        placeholder="Nachnamen eingeben"
                        value={lastname}
                        onChange={onChangeLastname}
                        name="lastname"
                      />
                    </div>
                  )
              )}

            {editable &&
              isOnline &&
              userform.map(
                (userform) =>
                  userform.email && (
                    <div key={userform.id}>
                      {userform.email && (
                        <motion.input
                          whileTap={tapAnimation}
                          required
                          type="email"
                          placeholder="E-Mail eingeben"
                          value={mail}
                          onChange={onChangeMail}
                          name="mail"
                        />
                      )}
                    </div>
                  )
              )}
            {editable &&
              isOnline &&
              userform.map(
                (userform) =>
                  userform.phone && (
                    <div key={userform.id}>
                      {userform.phone && (
                        <motion.input
                          whileTap={tapAnimation}
                          required
                          type="tel"
                          placeholder="Telefonnummer eingeben"
                          value={phone}
                          onChange={onChangePhone}
                          name="phone"
                        />
                      )}
                    </div>
                  )
              )}
            {editable &&
              isOnline &&
              userform.map(
                (userform) =>
                  userform.message && (
                    <div key={userform.id} className="userform-content-full">
                      {userform.message && (
                        <motion.input
                          whileTap={tapAnimation}
                          className="userform-message"
                          required
                          type="text"
                          placeholder="Nachricht eingeben"
                          value={message}
                          onChange={onChangeMessage}
                          name="message"
                        />
                      )}
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.colorchoice && (
                    <div key={userform.id}>
                      {userform.colorchoice && (
                        <label className="userform-label">Primärfarbe</label>
                      )}
                      {userform.colorchoice && (
                        <motion.input
                          whileTap={tapAnimation}
                          className="input-primary-color"
                          required
                          type="color"
                          placeholder="Choose color"
                          value={primaryColor}
                          onChange={onChangePrimaryColor}
                        />
                      )}
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.colorchoice && (
                    <div key={userform.id}>
                      {userform.colorchoice && (
                        <label className="userform-label">Sekundärfarbe</label>
                      )}
                      {userform.colorchoice && (
                        <motion.input
                          whileTap={tapAnimation}
                          className="input-secondary-color"
                          required
                          type="color"
                          placeholder="Choose color"
                          value={secondaryColor}
                          onChange={onChangeSecondaryColor}
                        />
                      )}
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.colorchoice && (
                    <div key={userform.id}>
                      {userform.colorchoice && (
                        <label className="userform-label">Tertiärfarbe</label>
                      )}
                      {userform.colorchoice && (
                        <motion.input
                          whileTap={tapAnimation}
                          className="input-tertiary-color"
                          required
                          type="color"
                          placeholder="Choose color"
                          value={tertiaryColor}
                          onChange={onChangeTertiaryColor}
                        />
                      )}
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.colorchoice && (
                    <div key={userform.id}>
                      {userform.colorchoice && (
                        <label className="userform-label">
                          Hintergrundfarbe
                        </label>
                      )}
                      {userform.colorchoice && (
                        <motion.input
                          whileTap={tapAnimation}
                          className="input-background-color"
                          required
                          type="color"
                          placeholder="Choose color"
                          value={backgroundColor}
                          onChange={onChangeBackgroundColor}
                        />
                      )}
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.colorchoice && (
                    <div key={userform.id}>
                      {userform.colorchoice && (
                        <label className="userform-label">Akzentfarbe</label>
                      )}
                      {userform.colorchoice && (
                        <motion.input
                          whileTap={tapAnimation}
                          className="input-active-color"
                          required
                          type="color"
                          placeholder="Choose color"
                          value={activeColor}
                          onChange={onChangeActiveColor}
                        />
                      )}
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.darkmodeswitch && (
                    <div id="choose-theme" key={userform.id}>
                      <label className="userform-label">Wähle ein Theme</label>
                      <Darkmode />
                    </div>
                  )
              )}
            {darkmodeActive &&
              userform.map(
                (userform) =>
                  userform.darkmodeswitch && (
                    <div key={userform.id}>
                      <label className="userform-label">
                        Wähle ein Theme (hell/dunkel)
                      </label>
                      <Darkmode darkmodeActive={darkmodeActive} />
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.savethemeoptions && (
                    <div id="userform-save-btn" key={userform.id}>
                      <motion.button
                        whileTap={tapAnimation}
                        className="btn-highlight"
                        onClick={onChangeSave}
                        type="submit"
                        style={{
                          display:
                            primaryColor ||
                            secondaryColor ||
                            tertiaryColor ||
                            backgroundColor ||
                            activeColor
                              ? "block"
                              : "none",
                        }}
                      >
                        {saveThemeDialogue}
                      </motion.button>
                    </div>
                  )
              )}
            {customizable &&
              userform.map(
                (userform) =>
                  userform.savethemeoptions && (
                    <div key={userform.id}>
                      <motion.button
                        whileTap={tapAnimation}
                        className="btn-outline"
                        onClick={onChangeReset}
                        type="submit"
                        style={{
                          display:
                            primaryColor ||
                            secondaryColor ||
                            tertiaryColor ||
                            backgroundColor
                              ? "block"
                              : "none",
                        }}
                      >
                        {resetThemeDialogue}
                      </motion.button>
                    </div>
                  )
              )}
            {editable &&
              userform.map(
                (userform) =>
                  userform.savepersonaldata && (
                    <motion.button
                      whileTap={tapAnimation}
                      className="btn-dark"
                      key={userform.id}
                      onClick={onChangeSave}
                      type="submit"
                      style={{
                        display:
                          firstname || lastname || mail || message
                            ? "block"
                            : "none",
                      }}
                    >
                      {saveDataDialogue}
                    </motion.button>
                  )
              )}
            {sendForm &&
              isOnline &&
              userform.map(
                (userform) =>
                  userform.sendMail && (
                    <div key={userform.id}>
                      <p
                        style={{
                          display:
                            firstname && lastname && mail && message
                              ? "block"
                              : "none",
                        }}
                        className="privacy-notice"
                        dangerouslySetInnerHTML={{
                          __html: userform.privacyText,
                        }}
                      />
                      <motion.button
                        whileTap={tapAnimation}
                        className="btn-highlight"
                        key={userform.id}
                        onClick={onChangeSave}
                        type="submit"
                        style={{
                          display:
                            firstname && lastname && mail && message
                              ? "block"
                              : "none",
                        }}
                      >
                        {userform.sendText}
                      </motion.button>
                      <p>{userform.infoText}</p>
                    </div>
                  )
              )}
          </form>
        </div>
      )}
    </>
  );
}

export default Userform;
