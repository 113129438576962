import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { motion, LayoutGroup, AnimatePresence, useInView } from "framer-motion";
import "./bentogrid.css";
import Icon from "../../helpers/Icon";

const defaultColumns = "repeat(12, 1fr)";
const defaultRows = "auto 1fr auto";

const BentoGrid = ({ gridData }) => {
  const { items, layout } = gridData;
  const { areas, rows, columns, gap } = layout;

  const [selectedId, setSelectedId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [zIndexMap, setZIndexMap] = useState({});
  const [currentScale, setCurrentScale] = useState(1);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const showItem = (index) => {
    const item = items[index];
    setActiveIndex(index);
    setZIndexMap((prev) => ({ ...prev, [index]: 1000 }));
    setSelectedId(item);
    setCurrentScale(0.9);
    setTimeout(() => {
      setCurrentScale(1);
    }, 200);
  };

  const hideItem = () => {
    setSelectedId(null);
    if (activeIndex !== null) {
      setTimeout(() => {
        resetZIndex(activeIndex);
        setActiveIndex(null);
      }, transitionSettings.duration * 1500);
    }
  };

  const resetZIndex = (index) => {
    setZIndexMap((prev) => ({ ...prev, [index]: 1 }));
  };

  useEffect(() => {
    const styleElement = document.createElement("style");
    document.head.appendChild(styleElement);

    const getAreas = () => {
      if (window.innerWidth <= 768) return areas.mobile || areas.default;
      if (window.innerWidth <= 992) return areas.tablet || areas.default;
      if (window.innerWidth <= 1200) return areas.desktop || areas.default;
      return areas.default;
    };

    const getColumns = () => {
      if (window.innerWidth <= 768) return columns.mobile || defaultColumns;
      if (window.innerWidth > 768 && window.innerWidth <= 992)
        return columns.tablet || defaultColumns;
      if (window.innerWidth > 992 && window.innerWidth <= 1200)
        return columns.desktop || defaultColumns;
      return columns.desktop || defaultColumns; 
    };

    const updateGridStyles = () => {
      const gridTemplateAreas = getAreas();
      const gridTemplateColumns = getColumns();
      const gridTemplateRows = rows || defaultRows;
      const gridGap = gap || "10px";

      styleElement.innerHTML = `
        .bento-grid {
          display: grid !important;
          width: 100% !important;
          grid-template-areas: ${gridTemplateAreas} !important;
          grid-template-columns: ${gridTemplateColumns} !important;
          grid-template-rows: ${gridTemplateRows} !important;
          gap: ${gridGap} !important;
        }
        ${items
          .map(
            (item, index) => `
            .bento-grid-item-${index} {
              grid-area: ${item.name || "auto"} !important;
            }
          `
          )
          .join("\n")}
      `;
    };

    updateGridStyles();
    window.addEventListener("resize", updateGridStyles);
    return () => {
      window.removeEventListener("resize", updateGridStyles);
      document.head.removeChild(styleElement);
    };
  }, [areas, columns, rows, gap, items]);

  const transitionSettings = {
    duration: 0.5,
    ease: "easeInOut",
  };

  return (
    <LayoutGroup>
      <div
        className="bento-grid"
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {items.map((item, index) => (
          <motion.div
            layoutId={`grid-item-${item.name}`}
            key={item.name}
            className={`bento-grid-item-${index} bento-grid-item fixed-border-radius`}
            style={{
              zIndex: zIndexMap[index] || 0,
              cursor: "pointer",
              transform: `scale(${currentScale})`,
              ...item.style,
            }}
            initial={{ opacity: 1, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            onClick={() => showItem(index)}
          >
            {item.headline && (
              <h4 className="bento-grid-colored-headline">{item.headline}</h4>
            )}
            {item.iconName && <Icon iconFa="fas" iconName={item.iconName} />}
            {item.content && (
              <div className="ellipsis-content">{item.content}</div>
            )}
            {item.image && (
              <img
                className="fixed-border-radius"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="grid-image"
                src={item.image}
              />
            )}
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {selectedId && (
          <motion.div
            className="bento-grid-fullscreen"
            onClick={hideItem}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={transitionSettings}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "var(--background-color-transparent)",
              zIndex: 1000,
              padding: "2rem",
              overflow: "auto",
            }}
          >
            <motion.div
              className="bento-grid-fullscreen-content fixed-border-radius"
              layoutId={`grid-item-${selectedId.name}`}
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={transitionSettings}
              style={{
                ...selectedId.style,
              }}
            >
              {selectedId.headline && (
                <h4 className="bento-grid-colored-headline">
                  {selectedId.headline}
                </h4>
              )}
              {selectedId.iconName && <Icon iconFa="fas" iconName="fa-minus" />}
              {selectedId.content && (
                <div className="expanded-content">{selectedId.content}</div>
              )}
              {selectedId.image && (
                <img
                  className="fixed-border-radius"
                  style={{ width: "100%" }}
                  alt="grid-image"
                  src={selectedId.image}
                />
              )}
            </motion.div>
            <button onClick={hideItem} className="btn btn-dark">
              Schließen
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </LayoutGroup>
  );
};

BentoGrid.propTypes = {
  gridData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        headline: PropTypes.string,
        iconName: PropTypes.string,
        content: PropTypes.node,
        image: PropTypes.string,
        style: PropTypes.object,
      })
    ).isRequired,
    layout: PropTypes.shape({
      areas: PropTypes.object.isRequired,
      rows: PropTypes.string,
      columns: PropTypes.object.isRequired,
      gap: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default BentoGrid;
